
import format from "format-util";
import { Component, Prop } from "vue-property-decorator";
import { BaseForm } from "../components/forms/BaseForm";
import { APIResponse } from "../services/http";

interface RealmAccount {
	type: "owner" | "association";
	publicId: string;
	displayName: string;
	profileId?: string;
	country?: string;
}

@Component({
	components: {},
})
export default class SelectAccount extends BaseForm {
	@Prop({ required: false })
	public declare readonly action: string;

	@Prop({ default: () => ({}) })
	public query!: Record<string, any>;

	@Prop({ required: true })
	public uid!: string;

	public accounts: RealmAccount[] = [];

	public ready = false;
	public valid = true;

	public model: { account: string | number | null } = {
		account: null,
	};

	public async mounted() {
		const response: APIResponse = await this.$store.dispatch("FETCH_OAUTH_ACCOUNTS", {
			id: this.uid,
			// eslint-disable-next-line @typescript-eslint/camelcase
			client_id: this.query.client_id,
		});
		if (!response.ok) {
			this.valid = false;
			this.ready = true;
			return;
		}
		this.accounts = response.data.accounts;
		if (this.accounts.length === 1) {
			// auto select the only account
			this.model.account = "0";
			return this.send();
		}
		this.ready = true;
	}

	public async send() {
		const account = this.accounts[Number(this.model.account)];
		if (!account) {
			return;
		}
		const response = await this.$api.post(format("/oauth/dialog/%s/accounts", this.uid), {
			type: account.type,
			publicId: account.publicId,
			country: account.country,
		});
		if (!response.ok) {
			if (response.statusCode === 404) {
				this.valid = false;
				return;
			}
			return this.handleError(response);
		}
		return this.$redirect(response.data.next);
	}
}
